.c-partner-hub-search-input {
  width: 50% !important;
  margin-bottom: var(--spacing-5);
}

.c-submit-button {
  padding: 0.5rem 2rem !important;
  border-radius: 2rem !important;
  margin-right: 1rem !important;
  border: 2px solid var(--red-700) !important;
  box-shadow: none !important;
  color: var(--red-700) !important;
  background-color: white !important;
}

.c-submit-button:focus,
.c-submit-button:hover,
.c-submit-button:active {
  background-color: var(--yellow-500) !important;
  box-shadow: none !important;
  border: 2px solid var(--yellow-500) !important;
  color: var(--black) !important;
}

.c-submit-button--is-selected:hover,
.c-submit-button--is-selected:focus {
  background-color: var(--yellow-500) !important;
}
