.c-partner-hub-card {
  color: var(--g-color-black-900);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.c-partner-hub-card--is-hero {
  max-width: 100%;
  width: 100% !important;
}

.c-partner-hub-card--is-hero > .c-partner-hub-card__title {
  font-size: var(--g-font-size-6x-large);
  font-weight: var(--g-font-weight-bold);
  line-height: var(--g-font-size-6x-large);
  text-transform: uppercase;
}

.c-partner-hub-card__image-container {
  border-radius: 10px;
  margin-bottom: var(--g-spacing-small);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-partner-hub-card__image {
  height: var(--g-partner-hub-card-height);
  object-fit: cover;
  transition: var(--g-animation-transition);
  width: 100%;
}

.c-partner-hub-card:hover .c-partner-hub-card__image {
  transform: scale(1.04);
  transform-origin: center;
}

.c-partner-hub-card__sub-title {
  color: #ce442a;
  font-size: var(--g-font-size);
  font-weight: 900;
  line-height: var(--g-font-size);
  margin-bottom: var(--g-spacing-small);
  text-transform: capitalize;
}

.c-partner-hub-card__title {
  font-size: var(--g-font-size-4x-large);
  font-weight: 900;
  line-height: var(--g-font-size-4x-large);
  margin-bottom: var(--g-spacing-small);
}

.c-partner-hub-card__container {
  padding: 0 var(--g-spacing-small) var(--g-spacing-large);
  width: 100%;
}

@media (min-width: 42em) {
  .c-partner-hub-card__container {
    width: 50%;
  }
}

@media (min-width: 64em) {
  .c-partner-hub-card__container {
    width: 33.333333%;
  }
}
